import React from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import slugify from '../../../util/slugify';

const TagCategory = ({ tag, color }) => {
  if (tag) {
    return (
      <a href={`artista?q=${encodeURIComponent(tag)}`} className={`cursor-pointer hover:bg-black hover:text-white text-${color} border-4 border-solid border-${color} py-1 px-4 bg-white mr-2 mb-2`}>
        <span className="font-stratos font-semibold text-base">{tag}</span>
      </a>
    );
  }
  return (<></>);
};

const ShowList = ({ categoriesList = [], refineNext = () => {} }) => (
  <div className="pb-12 laptop:pb-20 desktop:pb-12 w-full">
    {/* <h2 className="font-stratos font-semibold text-20 tablet:text-base desktop:text-22 text-black pb-4">
          Artistas coincidentes
    </h2> */}
    <div className="flex flex-wrap">
      {categoriesList.map((c, index) => <TagCategory key={`${c}_${index + 1}`} tag={c.replace(/^"(.*)"$/, '$1')} color="black" refine={refineNext} />)}
    </div>
  </div>
);

const InfiniteHits = ({ hits, refineNext, custom = false, queryFromUrl = '', query }) => {
  const [categoriesList, setCategoriesList] = React.useState([]);

  if (custom === 'artistsIndex' && queryFromUrl !== '') {
    return <ShowList categoriesList={[queryFromUrl]} refineNext={refineNext} />;
  }

  const filterResultsByCategories = () => {
    const allArtists = [];
    const querySlug = slugify(query);
    hits.forEach((item) => {
      const { postDescription } = item;
      const { relatedArtists } = postDescription;
      if (relatedArtists && relatedArtists.length >= 0) {
        relatedArtists.forEach((i) => {
          if (i.name) {
            if (querySlug === i.slug) {
              // If the artist matches exactly the searched string, put it as the first element of related artists:
              allArtists.unshift(i.name);
            } else {
              allArtists.push(i.name);
            }
          }
        });
      }
    });

    const distinctCategories = [...new Set(allArtists)];
    setCategoriesList(distinctCategories.slice(0, 10));
  };

  React.useEffect(() => {
    filterResultsByCategories(hits);
  }, [hits]);

  if (categoriesList.length > 0) return <ShowList categoriesList={categoriesList} refineNext={refineNext} />;
  return (<></>);
};

const SearchResultsRelatedArtists = connectInfiniteHits(InfiniteHits);

TagCategory.propTypes = {
  tag: PropTypes.string,
  color: PropTypes.string,
};

TagCategory.defaultProps = {
  tag: null,
  color: 'black',
};

InfiniteHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})),
  refineNext: PropTypes.func,
};

InfiniteHits.defaultProps = {
  hits: null,
  refineNext: () => {},
};

export default SearchResultsRelatedArtists;
