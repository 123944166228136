import React from 'react';
import PropTypes from 'prop-types';
import SimpleButton from './Buttons/SimpleButton';

const AccessPaywallPostComponent = ({ originPost, mustPay, consumedArticles }) => {
  const showLoginModal = () => {
    const loginEvent = new CustomEvent('loginMenuEvent', {
      detail: { close: false },
    });
    window.dispatchEvent(loginEvent);
  };

  return (
    <div>
      <div
        className="h-32 w-full -mt-32 z-50"
        style={{
          background: 'linear-gradient(to bottom, transparent 25%, #ffffff 75%)',
        }}
      />
      <div className="bg-white py-8">
        {mustPay && (
          <>
            <h2 className="text-red font-bold text-22 tablet:text-28 laptop:text-48 tracking-3 text-center">
              Contenido exclusivo
            </h2>
            <p className="text-center pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
              Para poder leer este contenido tienes que estar suscrito.
            </p>
            <div className="flex justify-center">
              <SimpleButton
                isLowercase
                text="Suscríbete"
                url="/suscribirse"
                borders="border-5 desktop:border-6"
                className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
              />
            </div>
          </>
        )}
        {consumedArticles && (
          <>
            <h2 className="text-red font-bold text-22 tablet:text-28 laptop:text-48 tracking-3 text-center">
              Has superado el límite
            </h2>
            <p className="text-center pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
              Gracias por tu interés, pero has superado el límite de artículos mensuales.
              <br />
              Puedes seguir leyendo Rockelux si te suscribes.
            </p>
            <div className="flex justify-center">
              <SimpleButton
                isLowercase
                text="Suscríbete"
                url="/suscribirse"
                borders="border-5 desktop:border-6"
                className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
              />
            </div>
          </>
        )}
        {!mustPay && !consumedArticles && (
          <>
            <h2 className="text-red font-bold text-22 tablet:text-28 laptop:text-48 tracking-3 text-center">
              Contenido exclusivo
            </h2>
            <p className="text-center pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
              Para poder leer el contenido tienes que estar registrado.
              <br />
              <span onClick={showLoginModal} className="text-red font-bold cursor-pointer">Regístrate</span>
              {' '}
              y podrás acceder a 3 artículos gratis al mes.
            </p>
            <div className="flex justify-center">
              <div className="mr-1 laptop:mr-2 desktop:mr-4">
                <SimpleButton
                  isLowercase
                  text="Suscríbete"
                  url={`/suscribirse${originPost && `?originPost=${originPost}`}`}
                  borders="border-5 desktop:border-6"
                  className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
                />
              </div>
              <div className="ml-1 laptop:ml-2 desktop:ml-4">
                <SimpleButton
                  isLowercase
                  text="Inicia sesión"
                  callback={showLoginModal}
                  borders="border-5 desktop:border-6"
                  className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AccessPaywallPostComponent.propTypes = {
  consumedArticles: PropTypes.bool.isRequired,
  mustPay: PropTypes.bool.isRequired,
  originPost: PropTypes.string,
};

AccessPaywallPostComponent.defaultProps = {
  originPost: null,
};

export default AccessPaywallPostComponent;
