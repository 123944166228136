import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import SubscriptionTag from '../SubscriptionTag';
import usePaywall from '../../../hooks/usePaywall';
import AccessPaywallPostComponent from '../AccessPaywallPostComponent';

const StandardArticlePage = ({ components }) => {
  const [deviceScreen, setDeviceScreen] = useState(null);

  const responsiveUtility = useRef();

  const {
    currentRolesAllowed,
    showPaywall,
    showPaywallConsumedArticles,
    showPaywallMustPay,
    mustVerify,
  } = usePaywall();

  const { slugName, rolesAllowed } = currentRolesAllowed;
  const showAccessPaywallComponent = showPaywall && !mustVerify;

  const handleResize = (v) => {
    setDeviceScreen(v);
  };

  const setParentComponent = () => {
    if (components && components.length > 0) {
      components.forEach((component) => {
        Object.assign(component, { parentComponent: 'StandardArticlePage' });
        if (component.type === 'TaggedItem') {
          Object.assign(component, { overlap: true });
        }
        if (Object.keys(component).includes('text')) {
          if (Object.keys(component.text).length > 0 && component.text.en) {
            if (['<p></p>', '<p><br></p>'].includes(component.text.en)) {
              Object.assign(component, { hideWhileEmptyText: true });
            }
          }
        }
        // console.log("🚀 ~ file: StandardArticlePage.jsx ~ line 31 ~ StandardArticlePage ~ components.forEach ~ component", component)
      });
    }
  };

  useEffect(() => {
    setParentComponent();
  }, [components]);

  useEffect(() => {
    const screen = responsiveUtility.current.deviceScreen(true);
    handleResize(screen);
    setParentComponent();
  }, []);

  const filteredComponents = components.filter((component) => !component.hideWhileEmptyText || component.hideWhileEmptyText !== true);

  let header = {};
  let tag = {};
  let title = {};
  let subTitle = {};
  let enhanced = {};
  let author = {};
  let date = {};
  let magazine = {};
  let socialTop = {};
  let socialBottom = {};
  let tags = {};
  let related = {};
  let comments = {};
  let body = [];

  if (filteredComponents && filteredComponents.length > 0) {
    header = filteredComponents.find((component) => component.type === 'MediaDescription' && typeof component.layout !== 'undefined' && ['head', 'head_alternative'].includes(component.layout)) || {};
    tag = filteredComponents.find((component) => component.type === 'TaggedItem') || {};
    title = filteredComponents.find((component) => component.type === 'TitleLineH1') || {};
    subTitle = filteredComponents.find((component) => component.type === 'TitleLineH2' && !component.enhanced) || {};
    enhanced = filteredComponents.find((component) => ((component.type === 'NormalBodyText' && component.enhanced === true) || (component.type === 'TitleLineH2' && component.enhanced === true))) || {};
    author = filteredComponents.find((component) => component.type === 'AuthorBlock') || {};
    date = filteredComponents.find((component) => component.type === 'DateBlock') || {};
    magazine = filteredComponents.find((component) => component.type === 'MagazineBlock') || {};
    socialTop = filteredComponents.find((component) => component.type === 'SharingBlock' && component.size !== 'small') || {};
    socialBottom = filteredComponents.find((component) => component.type === 'SharingBlock' && component.size === 'small') || {};
    tags = filteredComponents.find((component) => component.type === 'TagsBlock') || {};
    comments = filteredComponents.find((component) => component.type === 'CommentsBox') || {};
    related = filteredComponents.find((component) => component.type === 'GroupedItems') || {};

    const bodyItems = [];
    filteredComponents.forEach((item) => {
      if (![header, tag, title, subTitle, enhanced, author, date, magazine, socialTop, socialBottom, tags, comments, related].includes(item)) {
        bodyItems.push(item);
      }
    });
    body = bodyItems;
  }

  const itemsMissing = (Object.keys(subTitle).length === 0) || (Object.keys(enhanced).length === 0);
  const bodyStartsWithTitle = body[0].type.includes('TitleLine');
  const bodyStartsWithEnhancedText = body[0].type === 'NormalBodyText' && body[0].enhanced === true;

  if (bodyStartsWithTitle || bodyStartsWithEnhancedText) {
    Object.assign(body[0], { firstInBody: true });
  }

  return (
    <div key={slugName} className="full-width">
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={handleResize} rdlSizes />
      <div className="w-full">
        <div className="w-full">
          {filteredComponents && header && (
          <ComponentIterator components={[header]} />
          )}
        </div>
        <div className="grid w-full grid-cols-6 gap-3 px-4 tablet:px-11 laptop:px-90px tablet:grid-cols-12 laptop:gap-4 desktop:mx-auto max-w-rdl-xxl">
          <div className="z-10 col-start-1 col-span-6 tablet:col-span-12 laptop:col-start-2 laptop:col-end-12">
            {filteredComponents && (tag || title || subTitle || enhanced) && (
            <ComponentIterator components={[tag, title, subTitle, enhanced]} />
            )}
          </div>
        </div>
        {['laptop', 'desktop'].includes(deviceScreen) ? (
          <div className="w-full laptop:-mt-5 desktop:-mt-2">
            <div className={`grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl ${itemsMissing ? 'mt-8 laptop:mt-15' : ''}`}>
              <div className="col-start-2 col-end-4 mt-1 desktop:mt-2 pointer-events-auto">
                {filteredComponents && (author || date || magazine || socialTop) && (
                <ComponentIterator components={[author, date, magazine, socialTop]} />
                )}
              </div>
              <div className={`col-start-5 col-end-12 ${showAccessPaywallComponent ? 'h-255px overflow-hidden' : ''}`}>
                <SubscriptionTag className="mb-6" rolesAllowed={rolesAllowed} />
                {filteredComponents && body && body.length > 0 && (
                <ComponentIterator components={bodyStartsWithTitle ? [body[0], body[1]] : [body[0]]} />
                )}
              </div>
            </div>
            {!showAccessPaywallComponent && filteredComponents && body && body.map((bodyItem, index) => {
              if ((bodyStartsWithTitle && ![0, 1].includes(index)) || (!bodyStartsWithTitle && index !== 0)) {
                // MediaDescription layout variables
                if (bodyItem.type === 'MediaDescription') {
                  if (bodyItem.layout === 'over_text') {
                    return (
                      <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                        <div className="col-start-2 col-end-12">
                          <ComponentIterator components={[bodyItem]} />
                        </div>
                      </div>
                    );
                  }
                  if (bodyItem.layout === 'width_text') {
                    return (
                      <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                        <div className="col-start-5 col-end-12">
                          <ComponentIterator components={[bodyItem]} />
                        </div>
                      </div>
                    );
                  }
                  if (bodyItem.layout === 'none') {
                    return (
                      <div className={`w-full ${bodyItem.type === 'NormalBodyText' && bodyItem.quote === true ? 'my-1 tablet:my-16' : ''}`} key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    );
                  }
                }
                // Dynamic components
                if (bodyItem.type === 'NormalBodyText' && bodyItem.quote === true) {
                  return (
                    <div className={`w-full ${bodyItem.type === 'NormalBodyText' && bodyItem.quote === true ? 'my-1 tablet:my-16' : ''}`} key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                      <ComponentIterator components={[bodyItem]} />
                    </div>
                  );
                }
                if (['NormalBodyText', 'TitleLineH3', 'VideoPlayer'].includes(bodyItem.type)) {
                  return (
                    <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                      <div className="col-start-5 col-end-12">
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    </div>
                  );
                }
                if (bodyItem.type === 'NumberedList') {
                  return (
                    <div key={`ListsPostPage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                      <div className="col-start-4 col-end-12">
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                    <div className="col-start-2 col-end-12">
                      <ComponentIterator components={[bodyItem]} />
                    </div>
                  </div>
                );
              }
              return null;
            })}
            {showAccessPaywallComponent && (
              <div className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                <div className="col-start-5 col-end-12">
                  <AccessPaywallPostComponent originPost={slugName} mustPay={showPaywallMustPay} consumedArticles={showPaywallConsumedArticles} />
                </div>
              </div>
            )}
            <div className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
              <div className="col-start-2 col-end-4 mt-6">
                {filteredComponents && socialBottom && (
                <ComponentIterator components={[socialBottom]} />
                )}
              </div>
              <div className="col-start-5 col-end-12 mt-6">
                {filteredComponents && tags && (
                <ComponentIterator components={[tags]} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="grid grid-cols-6 col-start-1 col-end-6 gap-3 px-4 mt-12 tablet:grid-cols-12 tablet:px-11">
              <div className="col-start-1 col-end-4 row-start-1 laptop:mt-16">
                {filteredComponents && (author || date || magazine) && (
                <ComponentIterator components={[author, date, magazine]} />
                )}
              </div>
              <div className="col-start-5 col-end-7 row-start-1 tablet:col-start-11 tablet:col-end-13 laptop:mt-16 tablet:mt-4">
                {filteredComponents && socialTop && (
                <ComponentIterator components={[socialTop]} />
                )}
              </div>
            </div>
            <div className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
              <SubscriptionTag rolesAllowed={rolesAllowed} />
            </div>
            {!showAccessPaywallComponent && filteredComponents && body && body.map((bodyItem, index) => {
              // MediaDescription layout variables
              if (bodyItem.type === 'MediaDescription') {
                if (['over_text', 'width_text'].includes(bodyItem.layout)) {
                  return (
                    <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
                      <div className="col-start-1 col-end-7 tablet:col-end-13">
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    </div>
                  );
                }
                if (bodyItem.layout === 'none') {
                  return (
                    <div className="w-full" key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                      <ComponentIterator components={[bodyItem]} />
                    </div>
                  );
                }
              }
              // Dynamic components
              if (bodyItem.type === 'NormalBodyText' && bodyItem.quote === true) {
                return (
                  <div className="w-full" key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                    <ComponentIterator components={[bodyItem]} />
                  </div>
                );
              }
              return (
                <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
                  <div className="col-start-1 col-end-7 tablet:col-end-13">
                    <ComponentIterator components={[bodyItem]} />
                  </div>
                </div>
              );
            })}
            {showAccessPaywallComponent && body && (
            <div className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
              <div className="col-start-1 col-end-7 tablet:col-end-13 h-131px overflow-hidden">
                <ComponentIterator components={[body[0]]} />
              </div>
              <div className="col-start-1 col-end-7 tablet:col-end-13">
                <AccessPaywallPostComponent originPost={slugName} mustPay={showPaywallMustPay} consumedArticles={showPaywallConsumedArticles} />
              </div>
            </div>
            )}
            <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 px-4 tablet:px-11">
              <div className="tablet:row-start-1 col-start-1 col-end-7 tablet:col-start-4 tablet:col-end-13 tablet:mt-7">
                {filteredComponents && tags && (
                <ComponentIterator components={[tags]} />
                )}
              </div>
              <div className="tablet:row-start-1 col-start-1 col-end-3 mt-6 mt-7">
                {filteredComponents && socialBottom && (
                <ComponentIterator components={[socialBottom]} />
                )}
              </div>
            </div>

          </div>
        )}
        <div className="w-full mt-20 laptop:mt-32">
          <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 tablet:gap-4 px-4 tablet:px-11 laptop:px-90px">
            <div className="col-start-1 col-end-7 tablet:col-end-13 laptop:col-start-2 laptop:col-end-12">
              {filteredComponents && comments && (
              <ComponentIterator components={[comments]} />
              )}
            </div>
          </div>
          {filteredComponents && related && (
          <ComponentIterator components={[related]} />
          )}
        </div>
      </div>
    </div>
  );
};

StandardArticlePage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

StandardArticlePage.defaultProps = {
  components: null,
};

export default StandardArticlePage;
