import { useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useUser } from '../context/userProfileContext';
import RoutesContext from '../context/routesContext';

const cookieName = 'rdl_usedarticles';
const cookieLastPostName = 'rdl_lastvisited';
const numberOfFreePosts = 3;
const adminUserRole = 'admin';
const freeUserRole = 'rdl-registration';

const usePaywall = () => {
  const [showPaywall, setShowPaywall] = useState(false);
  const [
    showPaywallConsumedArticles,
    setShowPaywallConsumedArticles,
  ] = useState(false);
  const [showPaywallMustPay, setShowPaywallMustPay] = useState(false);
  const [showArticlesCounter, setShowArticlesCounter] = useState(false);
  const [counterHidden, setCounterHidden] = useState(false);
  const [articlesLeft, setArticlesLeft] = useState(null);
  const [mustVerify, setMustVerify] = useState(false);

  const { userRoles, isUserVerified, userEmail } = useUser();
  const { currentRolesAllowed = false } = useContext(RoutesContext);

  const readUsedArticles = () => {
    try {
      return JSON.parse(Cookies.get(cookieName) || '[]');
    } catch {
      return [];
    }
  };

  const checkLimitedAccess = () => {
    if (!currentRolesAllowed) return;

    const { rolesAllowed, slugName } = currentRolesAllowed;
    const usedArticles = readUsedArticles();
    const numberArticlesLeft = usedArticles?.length ? (Number(numberOfFreePosts) - usedArticles.length) : null;
    setArticlesLeft(numberArticlesLeft);

    if (
      rolesAllowed.includes(freeUserRole)
      && usedArticles?.length < numberOfFreePosts
      && !counterHidden
    ) {
      setShowArticlesCounter(true);
    }

    if (
      !usedArticles.includes(slugName)
      && usedArticles?.length < numberOfFreePosts
    ) {
      usedArticles.push(slugName);
      Cookies.set(cookieName, JSON.stringify(usedArticles), { expires: 30 });
    }
    // console.log('Is registered user with ', usedArticles.length, ' used articles of ', numberOfFreePosts);
    if (
      usedArticles?.length >= numberOfFreePosts
      && !usedArticles.includes(slugName)
    ) {
      setShowPaywall(true);
      setShowPaywallConsumedArticles(true);
    }
  };

  useEffect(() => {
    if (!currentRolesAllowed) return;

    const { rolesAllowed, url } = currentRolesAllowed;
    // console.log('allowed', rolesAllowed);
    // console.log('user', userRoles.map(role => (role === 'web' ? freeUserRole : role)));

    if (!rolesAllowed?.length) {
      // If the post doesn't have roles, we allow access to everybody
      setShowPaywall(false);
      return;
    }

    const userRolesWithoutRegistered = userRoles
      .map((role) => (role === 'web' ? freeUserRole : role))
      .filter((item) => item !== freeUserRole);

    const postRolesWithoutRegistered = rolesAllowed.filter(
      (item) => item !== freeUserRole,
    );

    if (userRolesWithoutRegistered.includes(adminUserRole)) {
      // is admin
      setShowPaywall(false);
    } else {
      // not admin
      // hasRole tells us if there are coincidences in role between the post and the user
      const hasRole = userRolesWithoutRegistered.some((r) => postRolesWithoutRegistered.indexOf(r) >= 0);
      if (hasRole) {
        // If we find the role, we allow access to the post
        setShowPaywall(false);
      } else {
        // We check if both the user and the post have the freeUserRole - if they do, they can access with a views limit
        const userRolesWithRegistered = userRoles.map((role) => (role === 'web' ? freeUserRole : role)).filter((item) => item === freeUserRole);
        const postRolesWithRegistered = rolesAllowed.filter((item) => item === freeUserRole);
        const hasFreeRole = userRolesWithRegistered.some((r) => postRolesWithRegistered.indexOf(r) >= 0);
        if (hasFreeRole) {
          if (!isUserVerified) {
            setShowPaywall(true);
            setMustVerify(true);
          } else {
            // It means the user is registered, and the post has the role. We check how many articles they have seen
            checkLimitedAccess();
          }
        } else {
          const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
          Cookies.set(cookieLastPostName, url, { expires: inFiveMinutes });
          // If they don't we show the paywall
          setShowPaywall(true);
          if (userRoles?.length > 0) {
            setShowPaywallMustPay(true);
          }
        }
      }
    }
  }, [currentRolesAllowed, userRoles]);

  return {
    currentRolesAllowed,
    showPaywall,
    showPaywallConsumedArticles,
    showPaywallMustPay,
    showArticlesCounter,
    counterHidden,
    articlesLeft,
    mustVerify,
    userEmail,
    setCounterHidden,
    setShowArticlesCounter,
  };
};

export default usePaywall;
