import React, { memo, useEffect, useState } from 'react';
import AccessPaywallOverlay from './AccessPaywallOverlay';
import ArticlesCounterPopup from './ArticlesCounterPopup';
import usePaywall from '../../hooks/usePaywall';

const AccessPaywall = () => {
  const [rolesAllowedInfo, setRolesAllowedInfo] = useState(false);

  const {
    currentRolesAllowed,
    showPaywall,
    showPaywallConsumedArticles,
    showPaywallMustPay,
    showArticlesCounter,
    counterHidden,
    articlesLeft,
    mustVerify,
    userEmail,
    setCounterHidden,
    setShowArticlesCounter,
  } = usePaywall();

  const lockScrolling = (lock) => {
    const body = document.querySelector('body');
    const fakeBody = document.querySelector('#body');
    if (lock) {
      body.classList.add('overflow-hidden');
      body.style.overflowY = 'hidden';
      body.style.height = '80vh';
      if (fakeBody) {
        fakeBody.classList.add('overflow-hidden');
        fakeBody.style.overflowY = 'hidden';
        fakeBody.style.height = '80vh';
      }
    } else {
      body.classList.remove('overflow-hidden');
      body.style.overflowY = 'auto';
      body.style.height = 'initial';
      if (fakeBody) {
        fakeBody.classList.remove('overflow-hidden');
        fakeBody.style.overflowY = 'auto';
        fakeBody.style.height = 'initial';
      }
    }
  };

  const handleCloseCounter = () => {
    setCounterHidden(true);
    setShowArticlesCounter(false);
  };

  useEffect(() => {
    if (currentRolesAllowed) {
      setRolesAllowedInfo(currentRolesAllowed);
    }
  }, [currentRolesAllowed]);

  useEffect(() => {
    lockScrolling(showPaywall && mustVerify);
  }, [showPaywall, mustVerify]);

  if (showPaywall && mustVerify) {
    const { slugName } = rolesAllowedInfo;
    return <AccessPaywallOverlay consumedArticles={showPaywallConsumedArticles} mustPay={showPaywallMustPay} mustVerify={mustVerify} originPost={slugName} userEmail={userEmail} />;
  }
  if (showArticlesCounter && !counterHidden) {
    return <ArticlesCounterPopup consumedArticles={articlesLeft} closeCallback={handleCloseCounter} />;
  }
  return null;
};
AccessPaywall.propTypes = {
};
AccessPaywall.defaultProps = {
};

export default memo(AccessPaywall);
