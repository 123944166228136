import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import ScrollToTop from 'ComponentsPath/ScrollToTop';
import AccessPaywall from 'ComponentsPath/AccessPaywall';

import { getRouteConfig } from '../util/routesLocalization';
import withAllContexts from '../context/withAllContexts';
import RadioContextProvider from '../context/RadioContextProvider';

import BrazeProvider from '../context/useBraze';

import { RoutesProvider } from '../context/routesContext';

import { ComponentIteratorContextProvider } from '../context/ComponentIteratorContext';

import UserProfileContextProvider from '../context/userProfileContext';

const { AuthClient, AuthProvider } = require('Primavera/auth');

// import RoutesContext from '../context/routesContext';

export const AUTH_CLIENT = new AuthClient(process.env.GRAPHQL_SERVER_URL);

class App extends Component {
  constructor(props) {
    super(props);

    let userRoute;
    let launcher = null;
    let lineup = null;
    let radioShowsNow = null;
    let radioShowsHighlights = null;
    let radioShows = null;
    let radioShowsEpisodes = null;
    let singleRadioShow = null;
    let singleRadioShowRelated = null;
    let singleRadioShowEpisodes = null;
    let singleRadioEpisode = null;
    let singleRadioEpisodeRelated = null;
    let singleRadioShowTitle = null;
    let featuredRadioShows = null;

    /*
    To server-side-render components that fetch their own data AFTER componentDidMount (e.g. Launcher), we have to already fetch the data during
    the server-side-render and pass it via props.staticContext and window.__INITIAL_DATA__. The data is then stored in context modules (Context API)
    in componentWillMount. The components, such as the Launcher, will then use the data from their respective context module during the server-side-render.
    */

    /* global __isBrowser__:true */
    if (__isBrowser__) {
      const {
        userRoute: userRouteWindow,
        launcher: launcherWindow,
        lineup: lineupWindow,
        radioShowsNow: radioShowsNowWindow,
        radioShowsHighlights: radioShowsHighlightsWindow,
        radioShows: radioShowsWindow,
        radioShowsEpisodes: radioShowsEpisodesWindow,
        singleRadioShow: singleRadioShowWindow,
        singleRadioShowRelated: singleRadioShowRelatedWindow,
        singleRadioShowEpisodes: singleRadioShowEpisodesWindow,
        singleRadioEpisode: singleRadioEpisodeWindow,
        singleRadioEpisodeRelated: singleRadioEpisodeRelatedWindow,
        singleRadioShowTitle: singleRadioShowTitleWindow,
        featuredRadioShows: featuredRadioShowsWindow,
      } = window.__INITIAL_DATA__;

      userRoute = userRouteWindow;
      launcher = launcherWindow;
      lineup = lineupWindow;
      radioShowsNow = radioShowsNowWindow;
      radioShowsHighlights = radioShowsHighlightsWindow;
      radioShows = radioShowsWindow;
      radioShowsEpisodes = radioShowsEpisodesWindow;
      singleRadioShow = singleRadioShowWindow;
      singleRadioShowRelated = singleRadioShowRelatedWindow;
      singleRadioShowEpisodes = singleRadioShowEpisodesWindow;
      singleRadioEpisode = singleRadioEpisodeWindow;
      singleRadioEpisodeRelated = singleRadioEpisodeRelatedWindow;
      singleRadioShowTitle = singleRadioShowTitleWindow;
      featuredRadioShows = featuredRadioShowsWindow;
    } else {
      const {
        data: {
          userRoute: userRouteStaticContext,
          launcher: launcherStaticContext,
          lineup: lineupStaticContext,
          radioShowsNow: radioShowsNowStaticContext,
          radioShowsHighlights: radioShowsHighlightsStaticContext,
          radioShows: radioShowsStaticContext,
          radioShowsEpisodes: radioShowsEpisodesStaticContext,
          singleRadioShow: singleRadioShowStaticContext,
          singleRadioShowRelated: singleRadioShowRelatedStaticContext,
          singleRadioShowEpisodes: singleRadioShowEpisodesStaticContext,
          singleRadioEpisode: singleRadioEpisodeStaticContext,
          singleRadioEpisodeRelated: singleRadioEpisodeRelatedStaticContext,
          singleRadioShowTitle: singleRadioShowTitleStaticContext,
          featuredRadioShows: featuredRadioShowsStaticContext,
        },
      } = props.staticContext;

      userRoute = userRouteStaticContext;
      launcher = launcherStaticContext;
      lineup = lineupStaticContext;
      radioShowsNow = radioShowsNowStaticContext;
      radioShowsHighlights = radioShowsHighlightsStaticContext;
      radioShows = radioShowsStaticContext;
      radioShowsEpisodes = radioShowsEpisodesStaticContext;
      singleRadioShow = singleRadioShowStaticContext;
      singleRadioShowRelated = singleRadioShowRelatedStaticContext;
      singleRadioShowEpisodes = singleRadioShowEpisodesStaticContext;
      singleRadioEpisode = singleRadioEpisodeStaticContext;
      singleRadioEpisodeRelated = singleRadioEpisodeRelatedStaticContext;
      singleRadioShowTitle = singleRadioShowTitleStaticContext;
      featuredRadioShows = featuredRadioShowsStaticContext;
    }

    this.state = {
      // language: 'en',
      // loadTokenForLS: () => this.loadTokenForLS(),
      // token: null,
      userRoute,
      launcher,
      lineup,
      radioShowsNow,
      radioShowsHighlights,
      radioShows,
      radioShowsEpisodes,
      singleRadioShow,
      singleRadioShowRelated,
      singleRadioShowEpisodes,
      singleRadioEpisode,
      singleRadioEpisodeRelated,
      singleRadioShowTitle,
      featuredRadioShows,
    };
  }

  componentWillMount() {
    const {
      userRoute,
      // loadTokenForLS,
      launcher,
      lineup,
      radioShowsHighlights,
      radioShows,
      radioShowsEpisodes,
      singleRadioShow,
      singleRadioShowRelated,
      singleRadioShowEpisodes,
      singleRadioEpisode,
      singleRadioEpisodeRelated,
      singleRadioShowTitle,
      featuredRadioShows,
    } = this.state;

    const {
      routesContext,
      launcherContext,
      lineupContext,
      radioShowsContext,
      radioShowsEpisodesContext,
    } = this.props;

    if (userRoute) {
      routesContext.updateUserRoute(userRoute.language, userRoute.enteredFrom);
      // this.setState({
      //   language: userRoute.language
      // })
    }

    // We will init on mount session
    // loadTokenForLS()

    // We clear all data from the context modules, before we update them with fresh data.
    launcherContext.clearLauncher();
    lineupContext.clearLineup();

    radioShowsEpisodesContext.clearAll();
    radioShowsContext.clearAll();

    if (launcher) {
      launcherContext.updateLauncher(launcher.highlights);
    }

    if (lineup) {
      lineupContext.updateLineup(lineup.lineupData);
    }

    if (radioShowsHighlights) {
      radioShowsContext.update('radioShowsHighlightsData', radioShowsHighlights.data);
    }

    if (radioShows) {
      radioShowsContext.update('radioShowsData', radioShows.data);
    }

    if (radioShowsEpisodes) {
      radioShowsEpisodesContext.update('radioShowsEpisodesData', radioShowsEpisodes.data);
    }

    if (singleRadioShow) {
      radioShowsContext.update('singleRadioShowData', singleRadioShow.data);
    }

    if (singleRadioShowRelated) {
      radioShowsContext.update('singleRadioShowRelatedData', singleRadioShowRelated.data);
    }

    if (singleRadioShowEpisodes) {
      radioShowsContext.update('singleRadioShowEpisodesData', singleRadioShowEpisodes.data);
    }

    if (singleRadioEpisode) {
      radioShowsContext.update('singleRadioEpisodeData', singleRadioEpisode.data);
    }

    if (singleRadioEpisodeRelated) {
      radioShowsEpisodesContext.update('singleRadioEpisodeRelatedData', singleRadioEpisodeRelated.data);
    }

    if (singleRadioShowTitle) {
      radioShowsContext.update('singleRadioShowTitleData', singleRadioShowTitle.data);
    }

    if (featuredRadioShows) {
      radioShowsContext.update('featuredRadioShowsData', featuredRadioShows.data);
    }

    // console.log(`${new Date().toLocaleTimeString()} ~ Client / App: AllContexts = ${JSON.stringify(this.context.lineupContext)}`);
  }

  render() {
    const { userRoute, radioShowsNow } = this.state;
    const { staticContext } = this.props;

    const isRDL = process.env.APP_NAME === 'rdl';

    const rdlHeaderClasses = isRDL ? 'mt-12 sm:mt-42px' : '';

    return (
      <ComponentIteratorContextProvider>
        <AuthProvider
          client={AUTH_CLIENT}
          context={userRoute}
          token={userRoute.cookieToken}
        >
          <RadioContextProvider staticContext={radioShowsNow}>
            <UserProfileContextProvider staticContext={userRoute}>
              <RoutesProvider>
                <BrazeProvider>
                  <>
                    <div className={`flex flex-col items-center relative ${rdlHeaderClasses}`}>
                      <Switch>
                        {getRouteConfig(userRoute.enteredFrom).map(({
                          path,
                          exact,
                          component: ComponentQuery,
                          ...rest
                        }) => (
                          <Route
                            key={0}
                            path={path}
                            exact={exact}
                          >
                            {isRDL ? <ComponentQuery {...{ staticContext }} {...rest} /> : <ComponentQuery {...rest} />}
                          </Route>
                        ))}
                      </Switch>
                    </div>
                    <AccessPaywall />
                    <ScrollToTop />
                  </>
                </BrazeProvider>
              </RoutesProvider>
            </UserProfileContextProvider>
          </RadioContextProvider>
        </AuthProvider>
      </ComponentIteratorContextProvider>
    );
  }
}

App.defaultProps = {
  staticContext: {},
  routesContext: {},
  launcherContext: {},
  lineupContext: {},
  radioShowsContext: {},
  radioShowsEpisodesContext: {},
};

App.propTypes = {
  staticContext: PropTypes.shape(),
  routesContext: PropTypes.shape(),
  launcherContext: PropTypes.shape(),
  lineupContext: PropTypes.shape(),
  radioShowsContext: PropTypes.shape(),
  radioShowsEpisodesContext: PropTypes.shape(),
};

export default withAllContexts(App);
