import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectMenu } from 'react-instantsearch-dom';
import dictionaryCategories from '../dictionaryCategories';

const Tag = ({ item, refine, color }) => {
  const {
    isRefined, label, count, value,
  } = item;
  const ttLabel = dictionaryCategories[label] ? dictionaryCategories[label] : label;
  return (
    <div
      key={value}
      onClick={(event) => {
        event.preventDefault();
        refine(isRefined ? '' : label);
      }}
      className={`cursor-pointer border-4 border-solid
      border-${color} py-1 px-2 tablet:px-4 bg-${color}
      tablet:bg-${isRefined ? color : 'white'} mr-2 mb-2`}
    >
      <span
        className={`hidden tablet:block laptop:block
        desktop:block font-stratos font-semibold
        text-${isRefined ? 'white' : color} text-base
        capitalize`}
      >
        {`${ttLabel}`}
      </span>
      <span
        className="tablet:hidden laptop:hidden desktop:hidden
        font-stratos font-semibold text-white text-base
        capitalize"
      >
        {`${ttLabel} (${count})`}
      </span>
    </div>
  );
};

const SearchResultsByTag = ({
  color,
  title,
  attribute,
  checkCategories,
}) => {
  const [hasItems, setHasItems] = useState(true);
  return (
    <div className="pb-12 laptop:pb-20 desktop:pb-12 w-full">
      <h2 className={`font-stratos font-semibold
        text-20
        tablet:text-base desktop:text-22
        text-${color} pb-4`}
      >
        {hasItems ? title : ''}
      </h2>
      <div className="flex flex-wrap">
        <CustomMenu
          attribute={attribute}
          checkCategories={checkCategories}
          color={color}
          updateHasItems={setHasItems}
          limit={20}
        />
      </div>
    </div>
  );
};

const menuList = ({
  items, refine, checkCategories, color, updateHasItems,
}) => {
  const test = (item) => {
    if (checkCategories.includes(item.label) || checkCategories.length === 0) {
      return (
        <Tag
          key={item.value}
          item={item}
          refine={refine}
          color={color}
        />
      );
    }
    return null;
  };
  updateHasItems(items.length > 0);
  if (items.length > 0) {
    // Set "hemeroteca" category at the end of the array
    const magazinePageCategory = items.find((i) => i.label === 'magazine-page');
    const sortedItems = items.filter((i) => i.label !== 'magazine-page');
    if (magazinePageCategory) {
      sortedItems.push(magazinePageCategory);
    }
    return sortedItems.map(test);
  }
  return null;
};

const CustomMenu = connectMenu(menuList);

Tag.propTypes = {
  item: PropTypes.shape(),
  refine: PropTypes.func,
  color: PropTypes.string,
};

Tag.defaultProps = {
  item: null,
  refine: null,
  color: 'red',
};

SearchResultsByTag.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  attribute: PropTypes.string,
  checkCategories: PropTypes.arrayOf(PropTypes.string),
};

SearchResultsByTag.defaultProps = {
  color: 'red',
  title: 'Categorías',
  attribute: 'postCategory',
  checkCategories: [
    'actualidad',
    'musica',
    'discos',
    'cultura',
    'lifestyle',
    'listas',
    'opinion',
    'magazine-page', // TODO: uncomment when the pdf highlight is resolved
    'en-portada',
  ],
};

export default SearchResultsByTag;
